import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'prRecoWithGl',
  components: { DatePicker },
  watch: {
    currentPage: function() {
      this.getPrRecoWithGlDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPrRecoWithGlDtls();
    }
  },
  data() {
    return {
      loader: false,
      showValueSetModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      legalEntity: {
        text: null,
        value: null
      },
      gstNumber: {
        text: null,
        value: null
      },
      period: {
        text: null,
        value: null
      },
      prRecoWithGlData: [],
      prRecoWithGlFields: [
        {
          key: 'heading',
          label: 'Reconciliation of Input Tax Receivables at Month-End',
          class: 'text-center width-fix-roco'
        },
        {
          key: 'igst',
          label: 'IGST',
          class: 'text-center'
        },
        {
          key: 'cgst',
          label: 'CGST',
          class: 'text-center'
        },
        {
          key: 'sgst',
          label: 'SGST',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      downPayload: null,
      closingBal: '31st March 2023',
      cgstAmt: 0,
      sgstAmt: 0,
      igstAmt: 0,
      totalAmt: 0
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    gstNumber: {
      text: { required }
    },
    period: {
      text: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && this.prRecoWithGlData.length) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.downPayload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'itcReconciliation/getPrRecoWithGlDtls',
            'GSTR_RECO_WITH_PL',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getPrRecoWithGlDtls() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          le_id: this.legalEntity.value,
          gst_number: this.gstNumber.value,
          period_name: this.period.value
        };
        this.downPayload = payload;
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/getPrRecoWithGlDtls', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data.page;
              let tIgst = 0;
              let tCgst = 0;
              let tSgst = 0;
              let tTotal = 0;
              for (let i = 0; i < result.length; i++) {
                tIgst += result[i].igst;
                tCgst += result[i].cgst;
                tSgst += result[i].sgst;
                tTotal += result[i].total;
              }
              const totalObj = {
                heading: 'Total Balances to be as per GL',
                igst: tIgst + +this.igstAmt,
                cgst: tCgst + +this.cgstAmt,
                sgst: tSgst + +this.sgstAmt,
                total: tTotal + +this.totalAmt,
                boldFlag: true
              };
              result.push(totalObj);
              this.prRecoWithGlData = result;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    updatePrRecoWithGlTax() {
      if (this.legalEntity.text && this.gstNumber.text && this.period.text) {
        const payload = {
          LE_ID: this.legalEntity.value,
          GSTN_NO: this.gstNumber.value,
          PERIOD_NAME: this.period.value,
          IGST: +this.igstAmt,
          CGST: +this.cgstAmt,
          SGST: +this.sgstAmt,
          HEADING: this.closingBal
        };
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/updatePrRecoWithGlTax', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 201) {
              this.getPrRecoWithGlDtls();
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.$bvToast.toast('Please select LOV first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.gstNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.period = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.gstNumber = {
          text: null,
          value: null
        };
        this.period = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.gstNumber.value) {
        this.gstNumber = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.period.value) {
        this.period = {
          text: null,
          value: null
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.gstNumber = {
        text: null,
        value: null
      };
      this.period = {
        text: null,
        value: null
      };
      this.totalRows = null;
      this.prRecoWithGlData = [];
    },
    calTotalAmt() {
      this.totalAmt = 0;
      this.totalAmt = +this.igstAmt + +this.cgstAmt + +this.sgstAmt;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
